import axios from 'axios';
import moment from 'moment';
import api from '.';

// Create an axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

instance.interceptors.request.use(
  async (config) => {
    try {
      const storedAccessToken = localStorage.getItem('accessToken');
      if (storedAccessToken && storedAccessToken.length > 0 && storedAccessToken.includes('.')) {
        const tokenData = storedAccessToken.split('.')[1];
        const decoded = JSON.parse(atob(tokenData));
        const exp = new Date(decoded.exp * 1000);

        // Check if current token is outdated
        if (moment().isBefore(exp)) {
          config.headers.Authorization = `Bearer ${storedAccessToken}`;
        } else {
          const storedRefreshToken = localStorage.getItem('refreshToken');
          if (storedRefreshToken) {
            const response = await api.auth.refreshToken(storedRefreshToken);
            const { accessToken, refreshToken } = response.data;
            config.headers.Authorization = `Bearer ${accessToken}`;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
          }
        }
      }
    } catch (error) {
      console.error('Failed to intercept request:', error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
