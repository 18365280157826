import { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Title from '../components/title/Title.component';
import useFBPixel from '../hooks/useFBPixel';

interface LandingPageProps {
  id: string;
  inviteCode: string;
  img: string;
  action?: string;
}

// Initialize Google Analytics with Measurement ID
ReactGA.initialize(process.env.REACT_APP_GG_MEASUREMENT_ID as string);

const LandingPage = ({ id, inviteCode, img, action }: LandingPageProps) => {
  const [isTracked, setIsTracked] = useState(false);
  const hasTrackedRef = useRef(false);
  const { trackCustom } = useFBPixel(process.env.REACT_APP_FB_PIXEL_ID || '');

  // const mobileLink = `whatsapp://chat?code=${inviteCode}`;
  const webLink = `https://chat.whatsapp.com/${inviteCode}`;
  // const link = navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)
  //   ? mobileLink
  //   : webLink;
  const link = webLink;

  useEffect(() => {
    const trackPageView = async () => {
      try {
        if (hasTrackedRef.current) {
          return;
        }

        hasTrackedRef.current = true;

        await trackCustom('PageView', {
          id
        });
      } catch (error) {
        console.error('Failed to track page view:', error);
      } finally {
        setIsTracked(true);
      }
    };

    trackPageView();
  }, [id, trackCustom]);

  // Redirect to WhatsApp group link immediately if path includes '/join'
  useEffect(() => {
    if (action === 'join' && isTracked) {
      console.log('Redirecting to WhatsApp group link:', link);
      window.location.href = link;

      // Track the event after redirecting
      triggerWhatsAppClick();
    }
  }, [action, link, isTracked]);

  const triggerWhatsAppClick = () => {
    trackCustom('WhatsApp_Invite_Link_Click', {
      id
    });

    ReactGA.event({
      action: 'wa_click',
      category: 'WhatsApp',
      label: 'Join Group - Pets'
    });
  };

  return (
    <div
      className="min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 flex flex-col items-center text-white text-right"
      dir="rtl"
    >
      <Title title="הצטרף לקבוצת הוואטסאפ שלנו" />
      <div className="max-w-3xl mx-auto px-4 py-16 flex flex-col items-center">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-8 inline-block p-1 bg-white bg-opacity-20 rounded-full overflow-hidden transition-all duration-300 hover:bg-opacity-30"
          onClick={triggerWhatsAppClick}
        >
          <img
            src={img}
            alt="לוגו של הקבוצה"
            className="h-40 w-40 md:w-64 md:h-64 object-cover rounded-full filter drop-shadow-lg transition-all duration-300 hover:brightness-110"
          />
        </a>
        {action === 'join' ? (
          <>
            <h1 className="text-5xl font-bold mb-8">מעביר אותך לקבוצת הוואטסאפ...</h1>
            <p className="text-xl mb-12 text-center">אם לא העברנו אותך לקבוצת הוואטסאפ, לחץ על הכפתור למטה.</p>
            <a
              target="_blank"
              href={link}
              rel="noopener noreferrer"
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-full text-xl transition duration-300 ease-in-out flex items-center justify-center"
              onClick={triggerWhatsAppClick}
            >
              <FontAwesomeIcon icon={faWhatsapp} className="ml-2" />
              <span>הצטרף לקבוצת הוואטסאפ</span>
            </a>
          </>
        ) : (
          <>
            <a target="_blank" href={link} rel="noopener noreferrer" onClick={triggerWhatsAppClick}>
              <h1 className="text-5xl font-bold mb-8">קבל הצעות מעולות למוצרים</h1>
            </a>
            <p className="text-xl mb-12 text-center">
              הצטרף לקהילת הוואטסאפ שלנו וגלה מבצעים בלעדיים על מוצרים מדהימים!
            </p>
            <a
              target="_blank"
              href={link}
              rel="noopener noreferrer"
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-full text-xl transition duration-300 ease-in-out flex items-center justify-center"
              onClick={triggerWhatsAppClick}
            >
              <FontAwesomeIcon icon={faWhatsapp} className="ml-2" />
              <span>הצטרף לקבוצת הוואטסאפ</span>
            </a>
          </>
        )}
      </div>
      {/* <footer className='mt-16 text-sm opacity-75 text-center'>
        © 2024 החברה שלך. כל הזכויות שמורות.
      </footer> */}
    </div>
  );
};

export default LandingPage;
