import { configureStore } from '@reduxjs/toolkit';
import groupsReducer from './groupsSlice';
import authReducer from './authSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    groups: groupsReducer,
  },
});
