import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [, startTransition] = useTransition();

  const changeLanguage = (lng: string) => {
    startTransition(() => {
      i18n.changeLanguage(lng);
    });
  };

  const FlagButton = ({ language, flag }: { language: string; flag: string }) => (
    <button
      onClick={() => changeLanguage(language)}
      className={`p-0 m-1 rounded-full overflow-hidden transition-all 
         ${i18n.language === language ? 'ring-2 ring-blue-500 shadow-md' : 'hover:opacity-80'}`}
      style={{
        width: '2rem',
        height: '2rem',
        border: '2px solid transparent'
      }}
    >
      <img src={flag} alt={language} className="w-full h-full object-cover" />
    </button>
  );

  return (
    <div className="flex items-center justify-center space-x-2">
      <FlagButton language="he" flag={'./assets/locales/he.svg'} />
      <FlagButton language="en" flag={'./assets/locales/en.svg'} />
    </div>
  );
};

export default LanguageSwitcher;
