import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Initialize Google Analytics with Measurement ID
ReactGA.initialize(process.env.REACT_APP_GG_MEASUREMENT_ID as string);

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search
    });
  }, [location]);
};

export default usePageTracking;
