import { useEffect } from 'react';
import ReactPixel, { Options } from 'react-facebook-pixel';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000/api';

const useFBPixel = (pixelId: string) => {
  useEffect(() => {
    if (pixelId && pixelId.length > 0) {
      const options: Options = {
        autoConfig: true,
        debug: false
      };

      ReactPixel.init(pixelId, undefined, options);
      ReactPixel.pageView();
    } else {
      console.error('No Facebook Pixel ID provided');
    }
  }, [pixelId]);

  const trackCustom = async (eventName: string, data: ReactPixel.Data) => {
    ReactPixel.trackCustom(eventName, data);

    // Send the event to the backend
    try {
      await fetch(`${backendUrl}/track/facebook`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ eventName, customData: data })
      });
    } catch (error) {
      console.error('Failed to send event to the backend', error);
    }
  };

  return { trackCustom };
};

export default useFBPixel;
