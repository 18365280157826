import axios from './axios';
import { PaginateResult } from '../types/pagination';
import { NicheDocument } from '../types/niche';

export interface NicheFormData {
  _id?: string;
  name: string;
}

const getUserNiches = async ({
  query,
  page,
  dateRange
}: {
  query?: string;
  page?: number;
  dateRange?: {
    start: string;
    end: string;
  };
}) => {
  const filters: {
    [key: string]: any;
  } = {};
  if (query && query.trim().length > 0) {
    filters['name'] = query.trim();
  }

  if (page) {
    filters['page'] = page;
  }

  if (dateRange) {
    filters['dateRange'] = dateRange;
  }

  const response = await axios.get<PaginateResult<NicheDocument>>('/niche/myNiches', {
    params: filters
  });
  return response.data;
};

const createNiche = async (formData: NicheFormData) => axios.post<NicheDocument>('/niche', formData);

const deleteNiche = async (nicheId: string) => {
  const response = await axios.delete(`/niche/${nicheId}`);
  return response.status === 204;
};

const nicheApi = {
  getUserNiches,
  createNiche,
  deleteNiche
};

export default nicheApi;
