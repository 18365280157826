import React from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, sidebarClasses } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTags, faBoxes, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from '../components/languageSwitcher/LanguageSwitcher.component';
import * as authActions from '../redux/authSlice';

interface UserSidebarProps {
  collapsed: boolean;
  toggled: boolean;
  onToggle: () => void;
}

const UserSidebar = ({ collapsed, toggled, onToggle }: UserSidebarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const menuItems: {
    label: string;
    icon: React.ReactNode;
    link: string;
    subMenu?: { label: string; icon: React.ReactNode; link: string }[];
  }[] = [
    {
      label: t('sidebar.dashboard'),
      icon: <FontAwesomeIcon icon={faHome} />,
      link: '/'
    },
    {
      label: t('sidebar.niches'),
      icon: <FontAwesomeIcon icon={faTags} />,
      link: '/niche'
    },
    {
      label: t('sidebar.products'),
      icon: <FontAwesomeIcon icon={faBoxes} />,
      link: '/products'
    }
  ];

  return (
    <Sidebar
      rtl
      backgroundColor="white"
      width="240px"
      collapsedWidth="0px"
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onBreakPoint={onToggle}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <div className="p-4">
        <button onClick={onToggle} className="md:hidden absolute right-4 top-4 text-gray-600">
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </div>
      <div className="flex-1">
        <Menu>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              {!item.subMenu ? (
                <MenuItem key={index} icon={item.icon} component={<Link to={item.link} />}>
                  {item.label}
                </MenuItem>
              ) : (
                <>
                  {item.subMenu && (
                    <SubMenu label={item.label} icon={item.icon} key={index}>
                      {item.subMenu.map((subItem, subIndex) => (
                        <MenuItem key={subIndex} icon={subItem.icon} component={<Link to={subItem.link} />}>
                          {subItem.label}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </Menu>
      </div>
      <Menu
        menuItemStyles={{
          button: {
            [`&.active`]: {
              backgroundColor: '#13395e',
              color: '#b6c8d9'
            }
          }
        }}
      >
        <MenuItem component={<LanguageSwitcher />}></MenuItem>
        <MenuItem
          active={true}
          icon={<FontAwesomeIcon icon={faSignOutAlt} />}
          component={<Link to="#" />}
          onClick={() => {
            dispatch(authActions.logout());
            navigate('/auth');
          }}
        >
          {t('sidebar.logout')}
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default UserSidebar;
