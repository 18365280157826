import React from 'react';
import { Helmet } from 'react-helmet';

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={title} />
    </Helmet>
  );
};

export default Title;
